import { makeStyles } from '@material-ui/core/styles';

export const useDialogStyles = makeStyles({
  root: {
    '& .discount-date-picker': {
      '& .MuiInputBase-input': {
        color: '#575962',
      },
    },
    '& .MuiInputBase-root.select': {
      height: '43px',
    },
    '& .MuiInputBase-root .MuiSelect-selectMenu': {
      height: '21px',
    },
    '& .flex': {
      display: 'flex',
    },
    '& .full-width': {
      width: '100%',
    },
    '& .gap20': {
      gap: 20,
    },
    '& .select-title': {
      margin: '5px 0',
      color: '#575962',
      '& span': {
        color: 'red',
      },
    },
  },
  dialogContent: {
    '& .discount-code': {
      marginBottom: 30,
    },
  },
  dialogTitle: {
    padding: '16px 24px 0 24px',
    '& div': {
      color: '#575962',
      fontWeight: 700,
      margin: '5px 0',
      display: 'flex',
      justifyContent: 'space-between',
    },
    '& .close': {
      cursor: 'pointer',
    },
    '& hr': {
      display: 'block',
      height: '1px',
      border: 0,
      borderTop: '1px solid #E1E1E1',
      padding: 0,
    },
  },
});
