import { useEffect, useCallback, useState } from 'react';
import { ContentBody, ContentShadowAndWhite, NotResultFound } from 'styles/global-styles';
import { Button } from '@material-ui/core';

import useDebounce from 'hooks/useDebounce';

import Table from 'app/components/Table';
import RemoveDialog from './RemoveDialog';
import { getColumns, getRows } from './config';
import ManagementDialog from './ManagementDialog';

import Header from './Header';
import { useStyles } from './styles';
import { accountDetailsService } from 'services';
import { useDispatch } from 'react-redux';
import { throwErrorActions } from 'store/slices/throwError';

function DiscountContent() {
  const dispatch = useDispatch();
  const { root, header } = useStyles();
  const [count, setCount] = useState(0);
  const [discount, setDiscount] = useState([]);
  const [dialogKey, setDialogKey] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [selectedRow, setSelectedRow] = useState(null);
  const [needToResetPage, setNeedToResetPage] = useState(false);

  const debouncedValue = useDebounce(searchValue, 500);

  const getDiscounts = useCallback(async ({ term, offset = 0, limit = 10 }) => {
    try {
      const {
        data: { result, count },
      } = await accountDetailsService.getDiscounts({ term, offset, limit });

      setCount(count);

      setDiscount(result);
    } catch (error) {}
  }, []);

  useEffect(() => {
    getDiscounts({ term: debouncedValue });
  }, [getDiscounts, debouncedValue]);

  const openModal = id => {
    setSelectedRow(discount.find(discount => discount.id === id));
    setDialogKey('delete');
  };

  const editList = row => {
    setSelectedRow(discount.find(({ id }) => row.id === id));
    setDialogKey('manage');
  };

  const handleRemoveRow = useCallback(async () => {
    try {
      await accountDetailsService.deleteDiscount(selectedRow?.id);
      getDiscounts({ term: searchValue });

      setSelectedRow(null);
      setDialogKey(null);
    } catch (error) {
      dispatch(throwErrorActions.updateMessage({ message: error.response?.data.message }));
    }
  }, [dispatch, getDiscounts, searchValue, selectedRow?.id]);

  const handleCloseDialog = needToGetDiscount => {
    setSelectedRow(null);
    setDialogKey(null);

    needToGetDiscount && getDiscounts({});
  };

  const handleSearch = useCallback(e => setSearchValue(e.target.value), []);

  const handleChangePage = useCallback(
    (newPage, limit) => {
      getDiscounts({ term: searchValue, limit, offset: newPage * 10 });
    },
    [getDiscounts, searchValue],
  );

  return (
    <div className={root}>
      <ContentShadowAndWhite>
        <div className={header}>
          <div className="title-wrapper">
            <div className="title">Discount Codes</div>
            <div className="badge">{count} Discount Codes</div>
          </div>

          <Button color="primary" variant="contained" onClick={() => setDialogKey('manage')}>
            Create discount code
          </Button>
        </div>
        <ContentBody>
          <Header searchValue={searchValue} handleSearch={handleSearch} />
          {discount?.length ? (
            <Table
              quantity={count}
              rows={getRows(discount)}
              needToResetPage={needToResetPage}
              handleChangePage={handleChangePage}
              setNeedToResetPage={setNeedToResetPage}
              columns={getColumns(editList, openModal)}
            />
          ) : (
            <NotResultFound>No Result Found</NotResultFound>
          )}
        </ContentBody>
      </ContentShadowAndWhite>
      {dialogKey === 'manage' && (
        <ManagementDialog selectedRow={selectedRow} handleCloseDialog={handleCloseDialog} />
      )}
      {dialogKey === 'delete' && (
        <RemoveDialog
          selectedRow={selectedRow}
          handleRemove={handleRemoveRow}
          handleCloseDialog={handleCloseDialog}
        />
      )}
    </div>
  );
}

export default DiscountContent;
