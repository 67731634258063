import InputField from 'app/components/InputField';

const Title = ({ handleChange, value, name }) => (
  <InputField
    title="Title"
    value={value}
    handleChange={e => handleChange({ [name]: e.target.value })}
  />
);

export default Title;
