const data = [
  [{ title: 'Dashboard', pathname: '/dashboard', icon: 'AiFillDashboard' }],
  [
    { title: 'Online tickets', asTitle: true },
    { title: 'New', pathname: '/online-tickets', icon: 'AiTwotoneFileAdd' },
    {
      title: 'Modified',
      pathname: '/online-modified-tickets',
      icon: 'AiFillFileText',
    },
    {
      title: 'Photos',
      pathname: '/online-photo-tickets',
      icon: 'AiFillFileImage',
    },
  ],
  [
    { title: 'Offline tickets', asTitle: true },
    {
      title: 'New',
      pathname: '/offline-tickets',
      icon: 'AiTwotoneFileAdd',
    },
    {
      title: 'Declined modified',
      pathname: '/declinedModified',
      icon: 'AiFillCloseSquare',
    },
    {
      title: 'Modified',
      pathname: '/offline-modified-tickets',
      icon: 'AiFillFileText',
    },
    {
      title: 'Photos',
      pathname: '/offline-photo-tickets',
      icon: 'AiFillFileImage',
    },
  ],
  [
    { title: 'Advertisement', asTitle: true },
    {
      title: 'Verifications',
      pathname: '/verifications',
      icon: 'AiOutlineVerified',
    },
  ],
  [
    { title: 'Commerce', asTitle: true },
    { title: 'Orders', pathname: '/orders', icon: 'AiOutlineShoppingCart' },
  ],
  [
    { title: 'Components', asTitle: true },
    {
      title: 'Dictionary',
      pathname: '/dictionary',
      icon: 'AiOutlineTranslation',
    },
    {
      title: 'Blacklisted words',
      pathname: '/black-listed-words',
      icon: 'AiOutlineStop',
    },
    { title: 'Watch list', pathname: '/watch-list', icon: 'AiFillEye' },
    { title: 'Messages', pathname: '/messages', icon: 'AiOutlineMessage' },
    {
      title: 'Category Settings',
      pathname: '/category-settings',
      icon: 'AiFillSetting',
    },
    {
      title: 'Fraud Prevention',
      pathname: '/fraud-prevention',
      icon: 'AiFillWarning',
    },
  ],
  [
    { title: 'Marketing', asTitle: true },
    { title: 'SEO MetaData', pathname: '/seo-static', icon: 'BsGraphUp' },
    {
      title: 'Discount',
      icon: 'AiFillTag',
      pathname: '/discount',
    },
  ],
  [
    { title: 'Logout', asTitle: true },
    { title: 'Log out', icon: 'AiOutlineLogout' },
  ],
];

if (process.env.REACT_APP_PRIVATE_ROUTE === 'analytics') {
  data.splice(data.length - 2, 0, [
    { title: 'Analytics', asTitle: true },
    { title: 'Analytics', pathname: '/analytics', icon: 'AiFillSignal' },
  ]);
}

export default data;
