import DeleteEdit from 'app/components/Dictionary/components/DeleteEdit';
import { format } from 'date-fns';

const colorsPerStatus = {
  used: '#7167CD',
  active: '#29C0A3',
  pending: '#ffb822',
  expired: '#F50157',
};

export const getColumns = (editList, openModal) => [
  { id: 'code', label: 'Discounts code' },
  { id: 'discountPercentageAmount', label: 'Discount percentage/amount' },
  { id: 'status', label: 'Status' },
  { id: 'activePeriod', label: 'Active period' },
  { id: 'dateCreated', label: 'Creation date' },
  { id: 'redemptions', label: 'Redemptions' },
  { id: 'revenue', label: 'Revenue' },
  { id: 'aov', label: 'AOV' },
  {
    id: 'actions',
    label: 'Actions',
    type: 'renderComponent',

    Component: ({ row, index }) => (
      <DeleteEdit index={index} row={row} editList={editList} deleteList={openModal} />
    ),
  },
];

export const defaultState = {
  code: '',
  end_date: null,
  start_date: null,
  discount_type: 'percentage_based',
  discount: null,
  minimum_order_amount: null,
  selected_products: [],
  account_id: null,
  per_user_limit: null,
  total_limit: null,
};

export const optionsMapping = {
  gold: 'Gold',
  Gold: 'gold',
  silver: 'Silver',
  Silver: 'silver',
  tagline: 'Tagline',
  Tagline: 'tagline',
  ad_bump: 'Ad Bump',
  'Ad Bump': 'ad_bump',
  fixed_amount: 'Fixed-amount discount (€)',
  'Fixed-amount discount (€)': 'fixed_amount',
  percentage_based: 'Percentage-based discount (%)',
  'Percentage-based discount (%)': 'percentage_based',
};

export const productOptions = ['Gold', 'Silver', 'Tagline', 'Ad Bump'];
export const discountOptions = ['Percentage-based discount (%)', 'Fixed-amount discount (€)'];

const formatDate = date => {
  const isoString = date.replace('Z', '');

  return format(new Date(isoString), 'dd.MM.yyyy');
};

export const getRows = pages =>
  pages?.map(
    ({
      id,
      code,
      status,
      revenue,
      discount,
      end_date,
      used_count,
      start_date,
      total_limit,
      date_created,
      discount_type,
    }) => ({
      id,
      code,
      _id: id,
      status: <span style={{ color: colorsPerStatus[status] }}>{status}</span>,
      revenue: `€${revenue}`,
      dateCreated: formatDate(date_created),
      aov: `€${(revenue / used_count || 0).toFixed(2)}`,
      redemptions: `${used_count}/${total_limit ?? '∞'}`, // ?
      discountPercentageAmount:
        discount_type === 'percentage_based' ? `${discount}%` : `€${discount}`,
      activePeriod: `${formatDate(start_date)} - ${formatDate(end_date)}`,
    }),
  );
