import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles({
  root: {
    '& .error': {
      color: 'red',
      fontSize: '10px',
      marginLeft: '5px',
    },
    '& .flex': {
      display: 'flex',
    },

    '& .input-title': {
      margin: '5px 0',
      color: '#575962',
      '& span': {
        color: 'red',
      },
    },
    '& .input-field': {
      marginBottom: '15px',
      '& input': {
        padding: '12px',
        color: '#575962',
      },
      '& .box': {
        width: '250px',
        color: '#575962',
        fontSize: '14px',
        padding: '10px 0',
        textAlign: 'center',
        background: '#717171',
        border: '1px solid #E1E1E1',
        borderRadius: '5px 0 0 5px',
        backgroundColor: '#F5F5F5;',
      },

      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #E1E1E1',
      },
    },
    '& .input-field.flex': {
      '& .MuiOutlinedInput-root': {
        borderRadius: '0 5px 5px 0',
      },
    },
  },
});
