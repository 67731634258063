import { AiOutlineSearch } from 'react-icons/ai';
import TextField from '@material-ui/core/TextField';

import { HeaderWrapper, SelectItem, IconInputWrapper } from 'styles/global-styles';

const Header = ({ handleSearch, searchValue }) => {
  return (
    <HeaderWrapper>
      <SelectItem>
        <IconInputWrapper direction="left">
          <AiOutlineSearch className="input--icon" color="#BBC0CE" size={16} />
          <TextField
            variant="outlined"
            size="small"
            placeholder="Search..."
            onChange={handleSearch}
            value={searchValue}
            InputProps={{
              style: {
                paddingLeft: '20px',
                borderRadius: '4px',
                paddingTop: '4px',
                paddingBottom: '4px',
              },
            }}
          />
        </IconInputWrapper>
      </SelectItem>
    </HeaderWrapper>
  );
};

export default Header;
