import { useEffect, useCallback, useState } from 'react';
import { BsPlusCircle } from 'react-icons/bs';
import { ContentBody, ContentShadowAndWhite, NotResultFound } from 'styles/global-styles';

import { cmsService } from 'services/cms';
import { accountDetailsService, categorySettingsService } from 'services';
import useMount from 'hooks/useMount';
import useDebounce from 'hooks/useDebounce';
import { CATEGORIES, getSelectOptions, PRICING, pricingOptions } from './utils';

import Table from 'app/components/Table';
import { getColumns, getRows } from './config';
import Header from './Header';
import RemoveDialog from './RemoveDialog';
import LandingPageManagementDialog from './ManagementDialog';

import { useStyles } from './styles';
import { useDispatch } from 'react-redux';
import { throwErrorActions } from 'store/slices/throwError';

function SEOStatic() {
  const dispatch = useDispatch();
  const { root, header } = useStyles();
  const [count, setCount] = useState(0);
  const [pages, setPages] = useState([]);
  const [adFilters, setAdFilters] = useState({});
  const [dialogKey, setDialogKey] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [selectedRow, setSelectedRow] = useState(null);
  const [needToResetPage, setNeedToResetPage] = useState(false);

  const [filterOptions, setFilterOptions] = useState('none');

  const debouncedValue = useDebounce(searchValue, 500);

  const getPages = useCallback(async ({ has_dead_link, term, offset = 0, limit = 10 }) => {
    try {
      const {
        data: { result, count },
      } = await cmsService.getPages({ has_dead_link, term, offset, limit });

      setCount(count);

      setPages(result);
    } catch (error) {}
  }, []);

  useEffect(() => {
    getPages({ term: debouncedValue, has_dead_link: filterOptions === 'with_dead_link' });
  }, [getPages, debouncedValue, filterOptions]);

  useMount(() => {
    (async () => {
      try {
        const [filtersResponse, categoriesResponse, locationsResponse] = await Promise.all([
          accountDetailsService.getAdFilters(),
          categorySettingsService.getCategories(),
          accountDetailsService.getProvinces(),
        ]);

        const {
          data: { filters },
        } = filtersResponse;

        const {
          data: { categories },
        } = categoriesResponse;

        const { data } = locationsResponse;

        const result = Object.entries(filters).reduce((acc, [key, value]) => {
          if (key === CATEGORIES) {
            return acc;
          }

          if (key === PRICING) {
            acc[key] = pricingOptions;
          } else if (value.section) {
            Object.assign(
              acc,
              Object.entries(value.section).reduce((acc, [sectionKey, sections]) => {
                acc[sectionKey] = getSelectOptions(sections);
                return acc;
              }, {}),
            );
          } else {
            acc[key] = getSelectOptions(value.tabs || value);
          }

          return acc;
        }, {});

        const categoriesOptions = getSelectOptions(Object.values(categories));

        const allCities = data.flatMap(({ cities }) => cities);

        const sortedCities = allCities.sort((a, b) => a.name.localeCompare(b.name));

        result.categories = categoriesOptions;
        result.location = getSelectOptions(data, ['cities']);
        result.label = getSelectOptions(categoriesOptions.flatMap(({ sections }) => sections));
        result.city = getSelectOptions(sortedCities);

        setAdFilters(result);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    })();
  });

  const openModal = id => {
    setSelectedRow({ id });
    setDialogKey('delete');
  };

  const editList = useCallback(
    row => {
      setSelectedRow(pages.find(({ _id }) => row._id === _id));
      setDialogKey('manage');
    },
    [pages],
  );

  const handleRemoveRow = useCallback(async () => {
    try {
      await cmsService.removePage(selectedRow?.id);
      getPages({ has_dead_link: filterOptions === 'with_dead_link' });

      setDialogKey(null);
    } catch (error) {
      dispatch(throwErrorActions.updateMessage({ message: error.response?.data.message }));
    }
  }, [dispatch, filterOptions, getPages, selectedRow?.id]);

  const handleCloseDialog = needToGetPages => {
    setSelectedRow(null);
    setDialogKey(null);

    needToGetPages && getPages({});
  };

  const handleSearch = useCallback(e => setSearchValue(e.target.value), []);

  const handleFilterSelect = useCallback(selectedFilter => setFilterOptions(selectedFilter), []);

  const handleChangePage = useCallback(
    (newPage, limit) => {
      getPages({ term: searchValue, limit, offset: newPage * 10 });
    },
    [getPages, searchValue],
  );

  return (
    <div className={root}>
      <ContentShadowAndWhite>
        <div className={header}>
          <div className="title">Seo MetaData</div>
          <BsPlusCircle onClick={() => setDialogKey('manage')} className="add-icon" size={30} />
        </div>
      </ContentShadowAndWhite>
      <ContentShadowAndWhite>
        <ContentBody>
          <Header
            searchValue={searchValue}
            handleSearch={handleSearch}
            filterOptions={filterOptions}
            handleFilterSelect={handleFilterSelect}
          />
          {pages?.length ? (
            <Table
              quantity={count}
              rows={getRows(pages)}
              needToResetPage={needToResetPage}
              handleChangePage={handleChangePage}
              setNeedToResetPage={setNeedToResetPage}
              columns={getColumns(editList, openModal)}
            />
          ) : (
            <NotResultFound>No Result Found</NotResultFound>
          )}
        </ContentBody>
      </ContentShadowAndWhite>
      {dialogKey === 'manage' && (
        <LandingPageManagementDialog
          adFilters={adFilters}
          selectedId={selectedRow?._id}
          handleCloseDialog={handleCloseDialog}
        />
      )}
      {dialogKey === 'delete' && (
        <RemoveDialog handleRemove={handleRemoveRow} handleCloseDialog={handleCloseDialog} />
      )}
    </div>
  );
}

export default SEOStatic;
