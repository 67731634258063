/**
 * index.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { Provider } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import { createRoot } from 'react-dom/client';
import FontFaceObserver from 'fontfaceobserver';
import { HelmetProvider } from 'react-helmet-async';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

// Use consistent styling
import 'sanitize.css/sanitize.css';
import { App } from 'app';
import { ThemeProvider } from '@material-ui/core/styles';
import MuiTheme from 'styles/theme/mui-theme';

import store from 'store/configureStore';

const openSansObserver = new FontFaceObserver('Poppins', {});

// When Inter is loaded, add a font-family using Inter to the body
openSansObserver.load().then(() => {
  document.body.classList.add('fontLoaded');
});

const MOUNT_NODE = document.getElementById('root');

const root = createRoot(MOUNT_NODE);

root.render(
  <Provider store={store}>
    <HelmetProvider>
      <ThemeProvider theme={MuiTheme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <App />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </HelmetProvider>
  </Provider>,
);
