import 'date-fns';
import { format, isValid } from 'date-fns';

import { KeyboardDatePicker } from '@material-ui/pickers';

function DatePicker({
  selectedDate,
  setSelectedDate,
  dateFormat = 'MM/dd/yyyy',
  label = 'Date picker dialog',
  style = {},
  ...rest
}) {
  const handleDateChange = date => isValid(date) && setSelectedDate(format(date, dateFormat));
  return (
    <div style={{ width: '100%' }}>
      <KeyboardDatePicker
        label={label}
        margin="normal"
        format={dateFormat}
        value={selectedDate}
        id="date-picker-dialog"
        onChange={handleDateChange}
        style={{ borderBottom: '1px solid rgba(0,0,0,0.5)', ...style }}
        {...rest}
      />
    </div>
  );
}

export default DatePicker;
