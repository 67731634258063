import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {},
  header: {
    display: 'flex',
    padding: '16px 20px',
    justifyContent: 'space-between',
    borderBottom: '1px solid #DADEDE',
    '& .title': {
      fontSize: '20px',
    },
    '& .title-wrapper': {
      display: 'flex',
      alignItems: 'center',
      '& .badge': {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '10px',
        padding: '8px 20px',
        borderRadius: '4px',
        backgroundColor: '#C4C5D6',
        color: '#fff',
      },
    },
    '& .add-icon': {
      color: '#29C0A3',
      cursor: 'pointer',
    },
  },
});
