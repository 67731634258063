import Button from '@material-ui/core/Button';
import { DialogTitle } from '@material-ui/core';
import MUIDialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import { ReactComponent as FaClose } from 'images/faClose.svg';

import { useDialogStyles } from '../ManagementDialog/styles';

const RemoveDialog = ({ selectedRow, handleCloseDialog, handleRemove }) => {
  const { root, dialogTitle } = useDialogStyles();

  return (
    <MUIDialog open maxWidth="lg" className={root} disableBackdropClick onClose={handleCloseDialog}>
      <DialogTitle className={dialogTitle} id="alert-dialog-title">
        <div>
          <div className="title">Delete discount code</div>
          <div className="close">
            <FaClose width="20px" height="20px" onClick={handleCloseDialog} />
          </div>
        </div>
        <hr />
      </DialogTitle>
      <DialogContent style={{ minWidth: '860px' }}>
        <p>{`Are you sure you want to delete “${selectedRow.code}” discount code?`}</p>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleCloseDialog}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleRemove} color="secondary">
          Delete
        </Button>
      </DialogActions>
    </MUIDialog>
  );
};

export default RemoveDialog;
