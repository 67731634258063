import { makeStyles } from '@material-ui/core/styles';

export const useLinkAndFilterStyles = makeStyles({
  root: {
    '& .level-group .select-wrapper': {
      width: '100% !important',
    },
    '& .select-wrapper': {
      width: '256px !important',
      marginBottom: '10px',

      '& .disabled > .MuiSelect-select.Mui-disabled': {
        cursor: 'not-allowed !important',
      },

      '& .select-title': {
        height: '20px',
      },
    },
    '& .full-width': {
      width: '100%',
    },
    '& .m-20': {
      margin: '0 20px',
    },
  },
});

export const useDialogStyles = makeStyles({
  root: {
    '& .error': {
      color: 'red',
      fontSize: '10px',
      marginLeft: '5px',
    },
    '& .page-content': {
      '& .quill': {
        '& .ql-picker-label': {
          padding: `0 4px`,
        },
        '& .ql-formats': {
          '& button': {
            margin: 0,
          },
          marginRight: 0,
        },
      },
      minHeight: '300px',
      '& .box': {
        width: '32px',
      },
      '.button-group': {
        display: 'flex',
      },
    },
    '& .flex': {
      display: 'flex',
    },
    '& .dialog-actions': {
      justifyContent: 'space-between',
      padding: '8px 15px',
    },

    '& .advertisement': {
      gap: 30,

      '&.rowReverse': {
        flexDirection: 'row-reverse',
      },

      '&.flex': {
        display: 'flex',
      },

      '&.image-top': {
        '& .image-block': {
          width: '355px',
          margin: '0 auto',
        },
      },

      '.full-width': {
        width: '100%',
      },

      '& .menu-block': { display: 'flex', justifyContent: 'center' },
    },

    '& .image-block': {
      '& .icon-wrapper': {
        position: 'absolute',
        top: 10,
        right: 10,
        cursor: 'pointer',
        background: 'white',
        borderRadius: '50%',
        width: '30px',
        height: '30px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '& .image img': {
        width: '100%',
      },

      '& .button-block': {
        marginTop: '30px',
      },

      '& .flex-center': {
        display: 'flex',
        justifyContent: 'center',
      },
      '& .image-placeholder': {
        top: '50%',
        left: '50%',
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
      },
    },

    '& .first-step-title': {
      fontSize: '17px',
      margin: '0 0 20px 0',
      color: '#575962',
      '& span': {
        fontWeight: 700,
      },
    },

    '& button': {
      margin: '0 10px',
    },

    '& button.yellow': {
      backgroundColor: '#f1d504',
    },
    '& button.purple': {
      backgroundColor: '#7167cd',
    },
  },
  dialogTitle: {
    padding: '16px 24px 0 24px',
    '& div': {
      color: '#575962',
      fontWeight: 700,
      margin: '5px 0',
      display: 'flex',
      justifyContent: 'space-between',
    },
    '& .close': {
      cursor: 'pointer',
    },
    '& hr': {
      display: 'block',
      height: '1px',
      border: 0,
      borderTop: '1px solid #E1E1E1',
      padding: 0,
    },
  },
});
