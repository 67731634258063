import { Helmet } from 'react-helmet-async';
import DiscountContent from './DiscountContent';

export function Discount() {
  return (
    <>
      <Helmet>
        <title>Listing of discount codes</title>
        <meta name="Listing of discount codes" content="A React Boilerplate application homepage" />
      </Helmet>
      <DiscountContent />
    </>
  );
}
