import { memo } from 'react';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';

import { useStyle } from './styles';

const InputField = ({
  title,
  value,
  style,
  boxTitle,
  required,
  className,
  inputProps,
  placeholder,
  handleChange,
}) => {
  const { root } = useStyle();
  return (
    <div className={clsx(root, className)}>
      <div className="input-title">
        {required && <span>* </span>}
        {title}
      </div>
      <div className={clsx('input-field', { flex: boxTitle })} style={style}>
        {boxTitle && <div className="box">{boxTitle}</div>}
        <TextField
          fullWidth
          value={value}
          variant="outlined"
          onChange={handleChange}
          placeholder={placeholder}
          {...inputProps}
        />
      </div>
    </div>
  );
};

export default memo(InputField);
